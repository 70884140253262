















import Scrollbar from '@/app/components/Scrollbar.vue';
import { computed, defineComponent, onMounted, PropType, Ref, ref, watch } from '@vue/composition-api';
import * as d3 from 'd3';
import dagreD3 from 'dagre-d3';
import dayjs from 'dayjs';
import * as R from 'ramda';
import { BlockCategory, BlockType, ExecutionTypeWrapper } from '../../../constants';
import { DagLoop } from '../../../types/dag-loop.interface';
import { Task, WorkflowExecution } from '@/modules/workflow-designer/types';
import { useFailedTasks } from '@/modules/workflow-designer/composable';

const CANVAS_WIDTH = 12000;
const CANVAS_HEIGHT = 8000;

export default defineComponent({
    name: 'Dag',
    props: {
        tasks: { type: Object, required: true },
        selectedTask: { type: String, required: false },
        action: { type: String, default: null },
        stopClickPropagation: { type: Boolean, default: true },
        runningExecution: { type: Object, default: null },
        invalidTaskIds: {
            type: Array,
            default: () => [],
        },
        blockedExecutions: {
            type: Array as PropType<WorkflowExecution[]>,
            default: () => [],
        },
        validationErrors: {
            type: Array,
            default: () => [],
        },
        pipelines: {
            type: Array,
            default: () => [],
        },
        loops: {
            type: Array as PropType<DagLoop[]>,
            default: () => [],
        },
        readonly: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
        taskMap: {
            type: Object as PropType<Record<string, Task>>,
            default: () => {
                return {};
            },
        },
    },
    components: { Scrollbar },
    setup(props, { emit }) {
        const dagElement = ref<HTMLElement>();
        const currentTasks = ref(R.clone(props.tasks));
        const currentRunningTask = ref(null);
        const nodes = computed(() => props.tasks.nodes);
        const edges = computed(() => props.tasks.edges);

        let inner: any = null;
        let g: any = null;
        let svg: any = null;
        let zoom: any = null;
        let ren: any = null;

        const graphNodeColours = (colour: string) => {
            switch (colour) {
                case 'purple':
                    return {
                        iconBackground: 'bg-purple-600',
                        borderColor: 'border-purple-600',
                        textColour: 'text-purple-600',
                    };

                case 'blue':
                    return {
                        iconBackground: 'bg-blue-700',
                        borderColor: 'border-blue-700',
                        textColour: 'text-blue-700',
                    };

                case 'green':
                    return {
                        iconBackground: 'bg-green-800',
                        borderColor: 'border-green-800',
                        textColour: 'text-green-800',
                    };

                case 'orange':
                    return {
                        iconBackground: 'bg-orange-800',
                        borderColor: 'border-orange-800',
                        textColour: 'text-orange-800',
                    };

                case 'indigo':
                    return {
                        iconBackground: 'bg-cherry-600',
                        borderColor: 'border-cherry-600',
                        textColour: 'text-cherry-600',
                    };

                default:
                    return {
                        iconBackground: 'bg-red-600',
                        borderColor: 'border-red-600',
                        textColour: 'text-red-600',
                    };
            }
        };

        const runningExecutionTooltip = computed(() => {
            if (!R.isNil(props.runningExecution)) {
                const executionType = ExecutionTypeWrapper.find(props.runningExecution.type);
                return executionType?.message(props.runningExecution.status, props.runningExecution.task).message;
            }

            return 'A run is currently in progress';
        });

        const invalidSvg = (tooltip: string, major: boolean) => {
            const color = major ? 'text-red-500 hover:text-red-700' : 'text-orange-800 hover:text-orange-900';
            return `<div class="flex items-center" title="${tooltip}"><svg
                                class="w-5 h-5 cursor-help ${color}"
                                fill="currentColor"
                                viewBox="0 0 20 18"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                    clip-rule="evenodd"
                                ></path>
                            </svg></div>`;
        };

        const hasFailureSvg = (failureMessages: string[]) => {
            return `<span title="${
                failureMessages.length > 0
                    ? failureMessages.join('. ').replaceAll(/"/g, "'")
                    : 'The latest run of this task has failed'
            }"><svg
                    class="w-5 h-5 text-red-400 cursor-help hover:text-red-700"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                        clip-rule="evenodd"
                    ></path>
                </svg></span`;
        };

        const isBlockedSvg = () => {
            return `<span title="The execution of this task is blocked due to error of an upstream task">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none" viewBox="0 0 24 24"
                    stroke-width="2.5" stroke="currentColor"
                    class="w-4 h-4 text-neutral-500 cursor-help hover:text-neutral-700"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                    />
                </svg>
            </span>`;
        };

        const isEndLoopTaskSvg = (loopName: string) => {
            return `<span title="From here the execution of the loop '${loopName}' iterates to the start of the loop."><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4 text-purple-600 rotate-90 cursor-help hover:text-purple-700">
  <path fill-rule="evenodd" d="M2.232 12.207a.75.75 0 011.06.025l3.958 4.146V6.375a5.375 5.375 0 0110.75 0V9.25a.75.75 0 01-1.5 0V6.375a3.875 3.875 0 00-7.75 0v10.003l3.957-4.146a.75.75 0 011.085 1.036l-5.25 5.5a.75.75 0 01-1.085 0l-5.25-5.5a.75.75 0 01.025-1.06z" clip-rule="evenodd" />
</svg></span>
`;
        };

        const runningSvg = (colour: string, tooltip: any) => {
            return `<div title="${tooltip}">
                                    <svg
                                    class="w-5 h-5 animate-spin"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    >
                                        <circle
                                            class="opacity-50"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="${colour}"
                                            stroke-width="4"
                                        ></circle>
                                        <path
                                            class="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                    </svg>
                            </div>`;
        };

        const taskMap = computed(() => props.taskMap);
        const { getHasFailure, getFailureMessages, onChange } = useFailedTasks(taskMap);

        const graphNodeConfiguration = (task: any, iconBackground: string, borderColor: string, textColour: string) => {
            const running =
                props.runningExecution && props.runningExecution.task && props.runningExecution.task.id === task.id;

            const failureMessages = getFailureMessages(task);
            const hasFailure = getHasFailure(task);
            const isBlocked = !!props.blockedExecutions.find(
                (blockedExecution: any) => blockedExecution.task.id === task.id,
            );
            let isValid = true;
            let validationErrorMsg = null;
            let major = false;
            if (props.invalidTaskIds && props.invalidTaskIds.length) {
                isValid = !props.invalidTaskIds.includes(task.id);

                if (!isValid) {
                    const validationError: any = props.validationErrors.filter(
                        (vError: any) => vError.taskId === task.id,
                    );
                    validationErrorMsg = validationError[0].message;
                    major = validationError[0].major;
                }
            }

            // if this task is considered the end of a loop then show appropriate icon
            const isEndLoopTask = R.has(task.id, loopEndTasks.value);

            return {
                shape: 'rect',
                label: `<div class="flex flex-row col-span-1 bg-white rounded">
                            <div class="flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium rounded-l ${iconBackground}">
                                <span>${task.blockCategory.iconHtml}</span>
                            </div>
                            <div class="flex flex-row space-x-1 items-center justify-between pr-2 flex-1 truncate border-t border-b border-r rounded-r ${borderColor}">
                                <div class="flex flex-col flex-1 py-4 pl-4 text-sm truncate ${
                                    running || !isValid || hasFailure || isBlocked ? '' : 'pr-8'
                                }" >
                                    <div class="${textColour} select-none">
                                        ${task.name ? task.name : task.label}
                                    </div>
                                </div>
                                <div class="flex flex-row items-center space-x-1">
                                    ${hasFailure && !running ? hasFailureSvg(failureMessages) : ''}
                                    ${!isValid && !running ? invalidSvg(validationErrorMsg, major) : ''}
                                    ${
                                        running
                                            ? runningSvg(task.blockCategory.colour, runningExecutionTooltip.value)
                                            : ''
                                    }
                                    ${isEndLoopTask ? isEndLoopTaskSvg(loopEndTasks.value[task.id]) : ''}
                                    ${isBlocked ? isBlockedSvg() : ''}
                            </div>
                        </div>`,
                labelStyle: 'cursor:pointer; color:white; font-weight:bold;',
                labelType: 'html',
                padding: 1,
                ry: 5,
                rx: 5,
            };
        };

        const setClickEffectsOnNodes = () => {
            inner.selectAll('g.node').on('click', function selectNode(this: any, id: any) {
                if (props.stopClickPropagation) {
                    d3.event.stopPropagation();
                    d3.selectAll('rect').attr('class', 'label-container');
                    d3.select(this).select('rect').attr('class', 'selected label-container');
                    emit('show-settings', id);
                }
            });
        };

        /**
         * Calculates the current position/ scale of graph
         */
        const calculatePositionAndScale = () => {
            const currentCoords: any = inner.attr('transform').split(' ');
            const translate: any = currentCoords[0].split(/[(,)]/);

            return {
                translateWidth: translate[1],
                translateHeight: translate[2],
                scale: currentCoords[1].split(/[()]/)[1],
            };
        };

        const createGraph = () => {
            g = new dagreD3.graphlib.Graph({ compound: true }).setGraph({});
            g.graph().rankDir = 'LR';
            g.graph().marginx = 20;
            g.graph().marginy = 20;

            svg = d3.select('#dagre');
            inner = svg.append('g');

            // Set up zoom support
            zoom = d3.zoom().on('zoom', () => {
                inner.attr('transform', d3.event.transform);
            });

            // eslint-disable-next-line new-cap
            ren = new dagreD3.render();
        };

        const currentPipelines = ref<any>([]);
        const currentLoops = ref<DagLoop[]>([]);
        const loopEndTasks: Ref<Record<string, string>> = computed(() =>
            props.loops.reduce((acc: Record<string, string>, loop: DagLoop) => {
                if (R.isNil(loop.lastTask)) return acc;

                const task: Task = props.taskMap[loop.lastTask];
                // skip if train block
                if (task.block.category === BlockCategory.MachineLearning && task.block.type === BlockType.Train)
                    return acc;

                acc[loop.lastTask] = loop.name;
                return acc;
            }, {}),
        );
        const render = (update: boolean, newTask?: any) => {
            nodes.value.forEach((node: any) => {
                const { iconBackground, borderColor, textColour } = graphNodeColours(node.blockCategory.colour);
                g.setNode(node.id, graphNodeConfiguration(node, iconBackground, borderColor, textColour));
            });

            props.pipelines.forEach((pipeline: any) => {
                g.setNode(pipeline.name, {
                    label: pipeline.name,
                    labelStyle: 'opacity:0.5; font-weight:bold;',
                    clusterLabelPos: 'bottom',
                });

                pipeline.tasks.forEach((pTask: string | number) => {
                    g.setParent(pTask, pipeline.name);
                });
            });

            props.loops.forEach((loop: any) => {
                g.setNode(loop.id, {
                    label: loop.name,
                    labelStyle: 'opacity:0.5; font-weight:bold;',
                    style: `opacity:0.5;fill: ${loop.bgColour}`,
                    clusterLabelPos: 'bottom',
                });

                loop.tasks.forEach((pTask: string | number) => {
                    g.setParent(pTask, loop.id);
                });
            });

            edges.value.forEach((edge: any) => {
                g.setEdge(edge.from, edge.to, {
                    label: '',
                    style: 'stroke: #4a5568;',
                    arrowheadStyle: 'fill:#4a5568;stroke:#4a5568;',
                });
            });

            let newPosition: any = null;
            if (update) {
                newPosition = calculatePositionAndScale();
                svg.attr('viewBox', null);
                svg.call(zoom.transform, d3.zoomIdentity.scale(1));
            }

            if (g) {
                ren(inner, g);
            }

            setClickEffectsOnNodes();
            inner.selectAll('g.node').select('rect').attr('stroke', 'transparent').attr('fill', 'transparent');

            let tx = 0,
                ty = 0;
            const edgePaths = svg.select('g.edgePaths');
            if (edgePaths) {
                const { x, y } = edgePaths.node().getBBox();
                if (x < 0) tx = Math.abs(x);
                if (y < 0) ty = Math.abs(y);
            }

            if (update) {
                if (nodes.value.length) {
                    svg.call(
                        zoom.transform,
                        d3.zoomIdentity
                            .translate(newPosition.translateWidth, newPosition.translateHeight)
                            .scale(newPosition.scale),
                    );
                } else {
                    svg.call(zoom.transform, d3.zoomIdentity.translate(tx, ty).scale(1));
                }

                //ensuring the 'selected' class will be properly set into the current selected task
                inner
                    .selectAll('g.node')
                    .filter((nid: string) => nid === props.selectedTask)
                    .select('rect')
                    .attr('class', 'selected');

                //remove the remnant 'rect' element (which might remain and displays an unused border)
                inner
                    .selectAll('g.node')
                    .filter((nid: string) => nid === props.selectedTask)
                    .selectAll('rect')
                    .filter(function (_: any, i: number, rectNodes : any) {
                        // returning the last element (only if more than one exists)
                        return rectNodes.length > 1 && i === rectNodes.length - 1;
                    })
                    .remove();

                if (newTask) {
                    inner
                        .selectAll('g.node')
                        .filter((nid: string) => nid === newTask.id)
                        .select('rect')
                        .attr('class', 'selected');
                }
            } else {
                const initialScale = 1;
                svg.call(zoom.transform, d3.zoomIdentity.translate(tx, ty).scale(initialScale));
            }

            svg.attr('viewBox', `0 0 ${CANVAS_WIDTH} ${CANVAS_HEIGHT}`);
            svg.attr('width', CANVAS_WIDTH);
            svg.attr('height', CANVAS_HEIGHT);
        };

        onMounted(() => {
            createGraph();
            render(false);
        });

        const updateGraphNodes = (currentNodes: any, updatedNodes: any) => {
            let newTask: any = null;
            let deletedTask: any = null;
            updatedNodes.sort((t1: any, t2: any) => (dayjs.utc(t2.createdAt) > dayjs.utc(t1.createdAt) ? 1 : -1));
            // if a new block has been added
            if (updatedNodes.length > currentNodes.length) {
                newTask = { ...updatedNodes[0] };
                const { iconBackground, borderColor, textColour } = graphNodeColours(
                    updatedNodes[0].blockCategory.colour,
                );
                g.setNode(
                    updatedNodes[0].id,
                    graphNodeConfiguration(updatedNodes[0], iconBackground, borderColor, textColour),
                );
            } else {
                // if a block has been deleted
                currentNodes.forEach((cNode: any) => {
                    const exists = updatedNodes.some((uNode: any) => uNode.id === cNode.id);
                    if (!exists) {
                        deletedTask = cNode.id;
                    }
                });

                // if block was in pipeline, remove pipeline so it can be re-rendered
                let pipelineAffected = false;
                currentPipelines.value.forEach((cPipeline: any) => {
                    if (cPipeline.tasks.indexOf(deletedTask) !== -1) {
                        g.removeNode(cPipeline.name);
                        pipelineAffected = true;
                    }
                });

                let loopAffected = false;
                currentLoops.value.forEach((cLoop: any) => {
                    if (cLoop.tasks.indexOf(deletedTask) !== -1) {
                        g.removeNode(cLoop.id);
                        loopAffected = true;
                    }
                });

                g.removeNode(deletedTask);

                // remove all so pipeline cluster can be properly rendered
                if (pipelineAffected || loopAffected) {
                    inner.selectAll('g.clusters').remove();
                    inner.selectAll('g.nodes').remove();
                    inner.selectAll('g.edgePaths').remove();
                    inner.selectAll('g.edgeLabels').remove();
                }
            }

            render(true, newTask);
        };

        const updateGraphNodeConfig = (taskConfigChange: any, currentEdges: any) => {
            g.removeNode(taskConfigChange.id);
            const { iconBackground, borderColor, textColour } = graphNodeColours(taskConfigChange.blockCategory.colour);

            g.setNode(
                taskConfigChange.id,
                graphNodeConfiguration(taskConfigChange, iconBackground, borderColor, textColour),
            );

            const edgesToBeRedrawn = currentEdges.filter(
                (edge: any) => edge.from === taskConfigChange.id || edge.to === taskConfigChange.id,
            );

            edgesToBeRedrawn.forEach((dEdge: any) =>
                g.setEdge(dEdge.from, dEdge.to, {
                    label: '',
                    style: 'stroke: #4a5568;',
                    arrowheadStyle: 'fill:#4a5568;stroke:#4a5568;',
                }),
            );

            render(true);
        };

        const updateGraphEdges = (currentEdges: any, updatedEdges: any, taskConfigChange: any) => {
            const newEdges: any = [];
            const deletedEdges: any = [];
            // if new edge(s) has/ have been added
            updatedEdges.forEach((uEdge: any) => {
                const exists = currentEdges.some((cEdge: any) => JSON.stringify(uEdge) === JSON.stringify(cEdge));
                if (!exists) {
                    newEdges.push(uEdge);
                }
            });

            newEdges.forEach((nEdge: any) =>
                g.setEdge(nEdge.from, nEdge.to, {
                    label: '',
                    style: 'stroke: #4a5568;',
                    arrowheadStyle: 'fill:#4a5568;stroke:#4a5568;',
                }),
            );

            // if edge(s) has/ have been deleted
            currentEdges.forEach((cEdge: any) => {
                const exists = updatedEdges.some((uEdge: any) => JSON.stringify(cEdge) === JSON.stringify(uEdge));
                if (!exists) {
                    deletedEdges.push(cEdge);
                }
            });

            deletedEdges.forEach((dEdge: any) => g.removeEdge(dEdge.from, dEdge.to));

            if (newEdges.length || deletedEdges.length || taskConfigChange) {
                render(true);
                currentTasks.value.edges = [...updatedEdges];
            }
        };

        const updateGraph = (tasks: any) => {
            let taskConfigChange: any = null;

            const updatedNodes = tasks[0];
            const updatedEdges = tasks[1];

            const currentNodes = currentTasks.value.nodes;
            const currentEdges = currentTasks.value.edges;

            if (updatedNodes.length !== currentNodes.length) {
                // if a block has been added/ deleted
                updateGraphNodes(currentNodes, updatedNodes);
                currentTasks.value.nodes = [...updatedNodes];
            } else {
                // if the configuration (i.e. only display name of a task (for now)) has changed
                updatedNodes.forEach((uNode: any) => {
                    const displayNameChange = currentNodes.some(
                        (cNode: any) => uNode.id === cNode.id && uNode.name !== cNode.name,
                    );

                    const executionsChange = currentNodes.some(
                        (cNode: any) => JSON.stringify(cNode.executions) !== JSON.stringify(uNode.executions),
                    );

                    if (displayNameChange || executionsChange) {
                        taskConfigChange = uNode;
                    }
                });
                if (taskConfigChange) {
                    updateGraphNodeConfig(taskConfigChange, currentEdges);
                    currentTasks.value.nodes = [...updatedNodes];
                }
            }

            // if an edge has been added/ deleted or if a task has changed its input (i.e. its incoming edge)
            updateGraphEdges(currentEdges, updatedEdges, taskConfigChange);
        };

        watch(
            () => [props.tasks.nodes, props.tasks.edges],
            (tasks: any) => {
                if (g) {
                    updateGraph(tasks);
                }
            },
        );
        const currentInvalidTaskIds = ref([]);
        watch(
            () => props.invalidTaskIds,
            (invalidTaskIds: any) => {
                if (
                    (invalidTaskIds && invalidTaskIds.length && g) ||
                    (invalidTaskIds && !invalidTaskIds.length && currentInvalidTaskIds.value.length && g)
                ) {
                    render(true); // render(false) must check this again
                    currentInvalidTaskIds.value = invalidTaskIds;
                }
            },
        );

        onChange(() => render(true));

        const renderPipelinesAndLoops = (currentValue: any, newValue: any, loops: boolean) => {
            if (JSON.stringify(currentValue) !== JSON.stringify(newValue) && g) {
                // means that a config of a pipeline or loop has changed (e.g. pipeline/loop name, added/ deleted/ removed task)
                let renamedEntityValue = '';
                if (currentValue.length === newValue.length) {
                    currentValue.forEach((cEntity: any) => {
                        newValue.forEach((uEntity: any) => {
                            // if pipeline or loop was renamed, graph must be re-rendered
                            if (cEntity.name !== uEntity.name) {
                                renamedEntityValue = loops ? cEntity.id : cEntity.name; // TODO: further testing required
                                g.removeNode(renamedEntityValue);

                                inner.selectAll('g.clusters').remove();
                                inner.selectAll('g.nodes').remove();
                                inner.selectAll('g.edgePaths').remove();
                                inner.selectAll('g.edgeLabels').remove();
                            }
                        });
                    });
                }

                if (loops) {
                    let updatedLoop = null;

                    for (let i = 0; i < newValue.length; i++) {
                        const nLoop = newValue[i];
                        if (nLoop.tasks.length === 1) {
                            updatedLoop = currentValue.find(
                                (cLoop: any) => nLoop.id === cLoop.id && cLoop.tasks.length > nLoop.tasks.length,
                            );
                        }
                        if (updatedLoop) break;
                    }

                    if (updatedLoop) {
                        g.removeNode(updatedLoop.id);
                        inner.selectAll('g.clusters').remove();
                        inner.selectAll('g.nodes').remove();
                        inner.selectAll('g.edgePaths').remove();
                        inner.selectAll('g.edgeLabels').remove();
                    }
                }

                render(true);
            }
        };

        watch(
            () => props.pipelines,
            (pipelines: any) => {
                // means that a config of a pipeline has changed (e.g. pipeline name, added/ deleted task)
                renderPipelinesAndLoops(currentPipelines.value, pipelines, false);
                currentPipelines.value = R.clone(pipelines);
            },
            { deep: true, immediate: true },
        );

        watch(
            () => props.loops,
            (loops: any) => {
                // means that a config of a loop has changed (e.g. loop name, added/ deleted/ removed task)
                renderPipelinesAndLoops(currentLoops.value, loops, true);
                currentLoops.value = R.clone(loops);
            },
            { deep: true, immediate: true },
        );

        watch(
            () => props.runningExecution,
            (runExec: any) => {
                let runningTask = null;
                if (runExec && runExec.task && g) {
                    runningTask = props.tasks.nodes.find((uNode: any) => uNode.id === runExec.task.id);
                    currentRunningTask.value = runningTask;
                    updateGraphNodeConfig(runningTask, currentTasks.value.edges);
                } else if (!runExec && currentRunningTask.value) {
                    updateGraphNodeConfig(R.clone(currentRunningTask.value), currentTasks.value.edges);
                    currentRunningTask.value = null;
                }
            },
            { deep: true },
        );

        watch(
            () => props.selectedTask,
            (selectedT: any) => {
                d3.selectAll('rect').attr('class', 'label-container');
                if (selectedT && g) {
                    inner
                        .selectAll('g.node')
                        .filter((nid: string) => nid === selectedT)
                        .select('rect')
                        .attr('class', 'selected');
                }
            },
        );

        const executeGraphAction = (action: string) => {
            if (action && action !== 'TB' && action !== 'LR') {
                const newPosition = calculatePositionAndScale();
                let newScale = null;
                if (action === 'in') {
                    const zoomIn = 1.5 * newPosition.scale;
                    newScale = newPosition.scale < 3 ? zoomIn : newPosition.scale;
                } else if (action === 'out') {
                    const zoomOut = newPosition.scale / 1.5;
                    newScale = newPosition.scale > 0.3 ? zoomOut : newPosition.scale;
                } else if (action === 'restore') {
                    scrollToStart();
                    newScale = 1;
                    newPosition.translateWidth = 0;
                    newPosition.translateHeight = 0;
                } else {
                    // zoom graph to fit screen
                    scrollToStart();
                    newPosition.translateWidth = 0;
                    newPosition.translateHeight = 0;

                    const bounds = svg.node().getBBox();
                    const parent = svg.node().parentElement;
                    const fullWidth = parent.clientWidth;
                    const fullHeight = parent.clientHeight;

                    const [width, height] = [bounds.width, bounds.height];

                    const newScaleCalculation =
                        (0.95 / Math.max(width / fullWidth, height / fullHeight)) * newPosition.scale;

                    if (newScaleCalculation > 2) {
                        newScale = (0.85 / Math.max(width / fullWidth, height / fullHeight)) * newPosition.scale;
                    } else {
                        newScale = (0.95 / Math.max(width / fullWidth, height / fullHeight)) * newPosition.scale;
                    }
                }

                svg.call(
                    zoom.transform,
                    d3.zoomIdentity.translate(newPosition.translateWidth, newPosition.translateHeight).scale(newScale),
                );
            } else if (action) {
                // change graph orientation (Horizontal <-> Vertical)
                scrollToStart();
                g.graph().rankDir = action;
                g.graph().transition = (selection: any) => selection.transition().duration(250);
                render(true);
                g.graph().transition = (selection: any) => selection.transition().duration(0);

                // TODO: fix pipeline name position
                // inner.selectAll('g.cluster').each(function (this: any) {
                //     // const clusterCurrentCoords = d3.select(this).attr('transform');
                //     if (d3.select(this).select('rect').attr('x')) {
                //         const xCoord: any = d3.select(this).select('rect').attr('x');
                //         // const yCoord: any = d3.select(this).select('rect').attr('y');
                //         const currentCoords: any = d3.select(this).select('g.label').select('g').attr('transform');
                //         const translate: any = currentCoords.split(/[(,)]/);
                //         const yCoord = translate[2];
                //         d3.select(this)
                //             .select('g.label')
                //             .select('g')
                //             .attr('transform', `translate(${parseInt(xCoord, 10) + 10},${parseInt(yCoord, 10) - 10})`);
                //     }
                // });
            }
            emit('reset-action');
        };

        const scrollToStart = () => {
            if (dagElement.value) dagElement.value.scrollIntoView({ behavior: 'smooth' });
        };

        watch(
            () => props.action,
            (action: string) => {
                executeGraphAction(action);
            },
        );

        return { dagElement };
    },
});
