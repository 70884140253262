





































































































































































































































































import { ConfirmButton, LoaderCircle, Scrollbar } from '@/app/components';
import { S } from '@/app/utilities';
import { ChevronDownIcon, ChevronUpIcon, RefreshIcon, BanIcon, ClockIcon, XIcon } from '@vue-hero-icons/outline';
import { ExclamationCircleIcon } from '@vue-hero-icons/solid';
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import BlockedExecutionsMessage from './BlockedExecutionsMessage.vue';
import { ExecutionStatus, ExecutionType, ExecutionTypeWrapper } from '../../constants';
import { Execution, ExecutionLog, RunningExecution, Task, WorkflowExecution } from '../../types';
import { ExecutionLogs } from '../execution-history';
import { MonitoringAPI } from '@/app/api';
import { useAxios } from '@/app/composable';

export default defineComponent({
    name: 'RunningExecutionFooter',
    components: {
        Scrollbar,
        ChevronDownIcon,
        ChevronUpIcon,
        ExecutionLogs,
        LoaderCircle,
        RefreshIcon,
        BanIcon,
        ClockIcon,
        BlockedExecutionsMessage,
        XIcon,
        ConfirmButton,
        ExclamationCircleIcon,
    },
    props: {
        runningExecution: {
            type: Object as PropType<RunningExecution | null>,
            default: null,
        },
        pendingExecutions: {
            type: Array,
            default: () => {
                return [];
            },
        },
        workflowId: {
            type: String,
            required: true,
        },
        otherRunningExecutions: {
            type: Array,
            default: () => [],
        },
        failedExecutions: {
            type: Array as PropType<WorkflowExecution[]>,
            default: () => {
                return [];
            },
        },
        blockedExecutions: {
            type: Array as PropType<WorkflowExecution[]>,
            default: () => {
                return [];
            },
        },
        tasks: {
            type: Array as PropType<Task[]>,
            required: true,
        },
        newLogMessageArrived: {
            type: Object as PropType<ExecutionLog>,
            required: false,
        },
    },
    setup(props, { root }) {
        const expand = ref<boolean>(false);
        const logsContainer = ref<any>(null);
        const logsRef = ref<any>(null);
        const CANCEL_DRY_RUN_THRESHHOLD = 3 * 60 * 1000; //min * sec * ms => 3 min waiting to show the cancel button
        let intervalCancel: number;
        const showDryRunCancel = ref<boolean>(false);
        const executionLogs = ref<ExecutionLog[]>([]);
        const { exec } = useAxios(true);
        type extendExecution = Partial<Execution> & { blockName: string };
        const latestExecution = ref<extendExecution>();
        const disabledCanceling = ref<boolean>(false);

        const executionIdInFocus = computed(() =>
            props.runningExecution ? props.runningExecution.executionId : latestExecution.value?.id,
        );

        const getExecutionLogs = async () => {
            const res = await exec(MonitoringAPI.getLatestExecutionLogs(props.workflowId));
            executionLogs.value = res?.data.logs || [];
            const curTask = props.tasks.find((task) => task.id === res?.data.taskId);
            if (curTask) {
                latestExecution.value = {
                    id: res?.data.id,
                    status: res?.data.status,
                    type: res?.data.type,
                    blockName: curTask.displayName,
                };
            }
        };

        getExecutionLogs();

        const typeColors = {
            dry: 'text-blue-700 bg-blue-100',
            normal: 'text-primary-600 bg-primary-100',
            test: 'text-orange-900 bg-orange-200',
        };

        const executionText = (execution: { type: ExecutionType; task: Task }) => {
            const executionType = ExecutionTypeWrapper.find(execution.type);
            return executionType?.message(ExecutionStatus.Queued, execution.task).message;
        };

        const hasHistoryEntry = (execution: { type: ExecutionType; task: Task }) => {
            return !R.isNil(execution) && R.isNil(execution.task);
        };

        const pendingExecutionsWithMessages = computed(() => {
            return props.pendingExecutions.map((execution: any) => {
                return { ...execution, message: executionText(execution), hasHistory: hasHistoryEntry(execution) };
            });
        });

        const blockedExecutionsWithMessages = computed(() => {
            return props.blockedExecutions.map((execution: any) => {
                return { ...execution, message: executionText(execution), hasHistory: hasHistoryEntry(execution) };
            });
        });

        const blockerExecutions = computed(() => props.failedExecutions.filter((e: any) => e.blocker));

        const goToHistoryEntry = (execution: { type: ExecutionType; task: Task }) => {
            if (execution) {
                root.$router.push({
                    name: 'history',
                    params: { workflowId: props.workflowId },
                });
            }
        };

        const runningExecutionTooltip = computed(() => {
            if (!R.isNil(props.runningExecution) && S.has('task', props.runningExecution)) {
                const executionType = ExecutionTypeWrapper.find(props.runningExecution.type);
                return executionType?.message(props.runningExecution.status, props.runningExecution.task).message;
            }
            return null;
        });

        const runningExecutionHasHistory = computed(() =>
            !R.isNil(props.runningExecution)
                ? hasHistoryEntry(props.runningExecution as { type: ExecutionType; task: Task })
                : null,
        );

        const runningExecutionsWithMessages = computed(() => {
            const otherExecutionsWithMessages = props.otherRunningExecutions.map((execution: any) => {
                return { ...execution, message: executionText(execution), hasHistory: hasHistoryEntry(execution) };
            });

            if (R.isNil(props.runningExecution)) {
                return otherExecutionsWithMessages;
            }

            return [
                {
                    ...props.runningExecution,
                    runninghasHistory: runningExecutionHasHistory.value,
                    message: runningExecutionTooltip.value,
                },
                ...otherExecutionsWithMessages,
            ];
        });

        const executionCompleted = computed(
            () =>
                executionLogs.value?.length &&
                (executionLogs.value.find((log: any) => log.message === 'Execution completed') ||
                    executionLogs.value.find((log: any) => log.level === 'error')),
        );

        const showCancel = computed(() => {
            if (
                props.runningExecution?.executionId &&
                props.runningExecution?.type != ExecutionType.Dry &&
                !disabledCanceling.value
            ) {
                return true;
            }
            if (props.runningExecution?.type === ExecutionType.Dry && !disabledCanceling.value)
                return showDryRunCancel.value;

            return false;
        });

        watch(
            () => executionCompleted.value,
            () => {
                if (executionCompleted.value) disabledCanceling.value = false;
            },
        );

        watch(
            () => executionIdInFocus.value,
            async (currId, prevId) => {
                if (!R.isNil(prevId) && R.isNil(currId)) {
                    await getExecutionLogs();
                }
                if (R.isNil(prevId) && !R.isNil(currId)) {
                    executionLogs.value = [];
                }
            },
        );

        watch(
            () => props.runningExecution,
            async () => {
                if (props.runningExecution?.type === ExecutionType.Dry && props.runningExecution?.createdAt) {
                    showDryRunCancel.value = false;
                    clearInterval(intervalCancel);
                    const substract =
                        new Date().getTime() -
                        (props.runningExecution?.createdAt ? new Date(props.runningExecution?.createdAt).getTime() : 0);

                    if (substract > CANCEL_DRY_RUN_THRESHHOLD) {
                        showDryRunCancel.value = true;
                    } else {
                        intervalCancel = (setInterval(() => {
                            disabledCanceling.value = false;
                            if (props.runningExecution) {
                                showDryRunCancel.value = true;
                            }
                        }, CANCEL_DRY_RUN_THRESHHOLD - substract) as any) as number;
                    }
                }
            },
            { immediate: true },
        );

        watch(
            () => executionLogs.value,
            () => {
                if (logsRef.value) logsRef.value.scrollDown();
            },
        );

        watch(
            () => props.newLogMessageArrived,
            (curr, prev) => {
                if (
                    curr?.message &&
                    executionLogs.value &&
                    curr?.message !== prev?.message &&
                    (R.isNil(executionIdInFocus.value) || curr?.executionId === executionIdInFocus.value)
                ) {
                    executionLogs.value.push({
                        message: curr.message,
                        level: curr.level as string,
                        timestamp: curr.timestamp,
                        executionId: executionIdInFocus.value as string,
                        taskId: undefined,
                        executionErrorCodes: [],
                    });
                }
            },
        );

        return {
            expand,
            goToHistoryEntry,
            ExecutionType,
            runningExecutionsWithMessages,
            pendingExecutionsWithMessages,
            blockedExecutionsWithMessages,
            blockerExecutions,
            runningExecutionTooltip,
            runningExecutionHasHistory,
            executionLogs,
            typeColors,
            logsContainer,
            logsRef,
            executionCompleted,
            disabledCanceling,
            showCancel,
            getExecutionLogs,
            latestExecution,
        };
    },
});
