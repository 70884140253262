var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full p-4 overflow-hidden text-white border-neutral-300 bg-primary-700",class:{ 'border-t': !_vm.standalone, 'rounded-b': _vm.standalone && !_vm.hasPagination }},[_c('div',{ref:"contentRef",staticClass:"overflow-auto",style:(("max-height: " + _vm.maxHeight))},[(_vm.logs.length > 0)?_c('div',{staticClass:"relative flex flex-col h-full space-y-2"},_vm._l((_vm.sortedLogs),function(log,idx){return _c('div',{key:idx},[_c('div',{staticClass:"w-full font-mono text-xs sm:flex sm:items-center lg:text-sm"},[(_vm.workflowType === _vm.WorkflowType.DataCheckin)?_c('div',{staticClass:"flex-shrink-0 w-12"}):_vm._e(),_c('div',{staticClass:"flex items-center space-x-8"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                                content: _vm.modifiedTimestamp(log.timestamp),
                                classes: 'max-w-lg break-words',
                            }),expression:"{\n                                content: modifiedTimestamp(log.timestamp),\n                                classes: 'max-w-lg break-words',\n                            }"}],staticClass:"whitespace-nowrap"},[_vm._v(" "+_vm._s(_vm.modifiedTimestamp(log.timestamp))+" ")]),_c('div',{staticClass:"w-8 uppercase sm:text-center",class:[_vm.textColour(log.level)]},[_vm._v(" "+_vm._s(log.level)+" ")]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                                content: log.message.length >= 50 && log.message.length < 500 ? log.message : null,
                                classes: 'max-w-lg break-words',
                            }),expression:"{\n                                content: log.message.length >= 50 && log.message.length < 500 ? log.message : null,\n                                classes: 'max-w-lg break-words',\n                            }"}],staticClass:"w-full text-sm break-all line-clamp-1"},[(log.message.length < 500)?_c('span',[_vm._v(" "+_vm._s(_vm.getLogMessage(log))+" ")]):_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                    'The log message is too long. You may copy it to the Clipboard and paste it in an editor to view it.'
                                ),expression:"\n                                    'The log message is too long. You may copy it to the Clipboard and paste it in an editor to view it.'\n                                "}],on:{"click":_vm.copyToClipboard}},[_c('input',{attrs:{"id":"longMessage","type":"hidden"},domProps:{"value":log.message}}),_c('div',{staticClass:"text-left break-all line-clamp-1",class:{
                                        'hover:text-red-200': log.level === 'error',
                                        'hover:text-primary-200': log.level === 'info',
                                    }},[_vm._v(" "+_vm._s(log.message)+" ")])])])])])])}),0):_c('div',{staticClass:"flex items-center h-full p-4"},[_c('div',{staticClass:"font-mono text-sm text-white"},[_vm._v("No logs found!")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }