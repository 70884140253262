









































































import { ConfirmButton, Toggle } from '@/app/components';
import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import { TriggerCondition, Workflow, WorkflowTrigger } from '../../types';
import { XCircleIcon, ExclamationCircleIcon, ExclamationIcon } from '@vue-hero-icons/solid';
import { TriggerSummary } from '.';
import { v4 as uuidv4 } from 'uuid';
import { clone, invertObj } from 'ramda';
import { TRIGGER_MAP, TRIGGER_PIPELINE_STATUS_MAP } from '../../constants';
import { useWorkflowTriggers } from '../../composable';

export default defineComponent({
    name: 'Trigger',
    components: {
        Toggle,
        ConfirmButton,
        XCircleIcon,
        ExclamationCircleIcon,
        ExclamationIcon,
        TriggerSummary,
    },
    props: {
        workflow: {
            type: Object as PropType<Workflow>,
            required: true,
        },
        trigger: {
            type: Object as PropType<WorkflowTrigger>,
            required: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Object as PropType<WorkflowTrigger>,
        },
        disabled: {
            type: Boolean,
            default: true,
        },
        disabledMessage: {
            type: String,
        },
    },
    setup(props) {
        const workflow = computed(() => props.workflow);
        const { getTriggerSummary } = useWorkflowTriggers(workflow, false);

        const hoveredItem = ref(null);

        const isSelected = computed(() => props.selected?.id === props.trigger.id);

        const setToggleBgColor = () => (hoveredItem.value ? 'bg-neutral-200' : 'bg-neutral-100');
        const setToggleAdditionalInputClass = () => (!props.trigger.isEnabled ? 'bg-neutral-300' : '');

        const hasAccessToConditions = computed(() =>
            props.trigger.conditions.every((condition: any) => condition.hasAccess),
        );

        const hasInvalidInputAsset = computed(() =>
            props.trigger.conditions.some((condition: any) => condition.hasInvalidInputAsset),
        );

        const mapConditionForClient = (condition: TriggerCondition) => {
            if (condition.type) {
                condition.entityTypeId = condition.type;
                condition.type = TRIGGER_MAP[condition.type];
            }

            if (condition.checks?.status) {
                condition.checks.status = invertObj(TRIGGER_PIPELINE_STATUS_MAP)[condition.checks.status];
            }

            return condition;
        };

        const getSummary = () => {
            const mappedConditions = clone(props.trigger.conditions).map((condition: TriggerCondition) =>
                mapConditionForClient(condition),
            );
            return getTriggerSummary(mappedConditions);
        };

        return {
            hoveredItem,
            isSelected,
            setToggleBgColor,
            setToggleAdditionalInputClass,
            hasAccessToConditions,
            hasInvalidInputAsset,
            uuidv4,
            getSummary,
        };
    },
});
